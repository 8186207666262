<div class="card">
    <div class="card-header card-header-primary">
        User Profile
    </div>
    <div class="card-body">
        <div mat-dialog-content >
            <div class="form-data-loader" *ngIf="formDataLoading">
                <div class="spinner-container"></div>
            </div>
            <form [formGroup]="profileFormValidations" >
                <div class="col-md-12">
                    <mat-form-field >
                        <mat-label>Email</mat-label>
                        <input matInput required readonly [(ngModel)]="_userInfo.email" formControlName="email">
                    </mat-form-field>
                </div>
                
                <div class="col-md-8 pull-left">
                    <mat-form-field >
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" required formControlName="name" [(ngModel)]="_userInfo.name">
                        <mat-error *ngIf="checkError('name','required')">Name is required.</mat-error>
                        <mat-error *ngIf="checkError('name','maxlength')">Name should not be more than 30 characters.</mat-error>
                        <mat-error *ngIf="checkError('name','pattern')">Name is not valid.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 pull-left">
                    <mat-form-field >
                        <mat-label>Employee ID</mat-label>
                        <input matInput placeholder="Employee Id" type="text" formControlName="empId" [(ngModel)]="_userInfo.emp_id">
                        <mat-error *ngIf="checkError('empId','pattern')">Employee Id is not valid.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 pull-left">
                    <mat-form-field >
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Phone" required maxlength="10" formControlName="phone" [(ngModel)]="_userInfo.phone">
                        <mat-error *ngIf="checkError('phone','required')">Pame number is required.</mat-error>
                        <mat-error *ngIf="checkError('phone','minlength')">Phone number should be 10 digit length.</mat-error>
                        <mat-error *ngIf="checkError('phone','pattern')">Phone number is not valid.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 pull-left">
                    <mat-form-field>
                        <mat-label>Date Of Birth</mat-label>
                        <input matInput formControlName="dob" [(ngModel)]="_userInfo.dob" [matDatepicker]="picker" (dateInput)="updateDateFormat($event)" [readonly]="true">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-12 pull-left">
                    <mat-form-field >
                        <mat-label>Address</mat-label>
                        <input matInput placeholder="Address" type="text" formControlName="address" [(ngModel)]="_userInfo.address">
                    </mat-form-field>
                </div>

                <div class="col-md-6 pull-left">
                    <mat-form-field>
                        <mat-label>Select State</mat-label>
                        <mat-select name="state_id" formControlName="state_id" [(ngModel)]="_userInfo.state_id" (selectionChange)="getCityByState($event.value)">
                        <mat-option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6 pull-left">
                    <mat-form-field>
                        <mat-label>Select City</mat-label>
                        <mat-select name="city_id" formControlName="city_id" [(ngModel)]="_userInfo.city_id">
                        <mat-option *ngFor="let city of cityList" [value]="city.id">{{city.city_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <!-- <button *ngIf="_userInfo.is_active" mat-icon-button color="primary" aria-label="Active User">
                        <i class="material-icons icon-size-2x">task_alt</i>&nbsp;&nbsp;&nbsp;Active
                    </button>
                    <button *ngIf="!_userInfo.is_active" mat-icon-button aria-label="In-Active User">
                        <i class="material-icons icon-size-2x">unpublished</i>&nbsp;&nbsp;&nbsp;In-Active
                    </button> -->
                </div>
            </form>
    
        </div>
        <div mat-dialog-actions style="justify-content: space-between;">
            <button mat-raised-button class="btn btn-danger" (click)="onCancelClick()">Cancel</button>
            <button mat-raised-button class="btn btn-success" [class.spinner]="loading" [disabled]="!profileFormValidations.valid || loading" (click)="onSubmitClick()">Submit</button>
        </div>
    
    </div>
</div>
