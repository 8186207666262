import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class TkrServiceService {

  private _serverDomain = environment._serverDomain;
  private _serverUrl = environment._apiDomain + environment._extendedPath;

  private userInfo;

  private fbNodeStr;

  public USER_DIR = '';
  public projectMediaList = [];

  constructor(
    private _snackBar: MatSnackBar,
    private _http: HttpClient
  ) {

    this.fbNodeStr = environment.fbNode;

    //if(this._serverUrl)
     // this._serverUrl = environment._apiDomain + this._extendedPath;
  }

  detectBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 )) {
        return 'IE';//crap
    } else {
        return 'Unknown';
    }
  } 

  setFbNodePath(pathstr){
    this.fbNodeStr = pathstr;
  }
  getFbNodePath(){
    return this.fbNodeStr;
  }

  btoa(myObjStr){
    return btoa(unescape(encodeURIComponent(myObjStr)));
  }
  atob(myObjStr) {
    return decodeURIComponent(escape(atob(myObjStr)));
  }

  abcdef(str) {
    var key = '3A7B642A2A23B051';
    var s = [], j = 0, x, res = '';
    for (var i = 0; i < 256; i++) {
      s[i] = i;
    }
    for (i = 0; i < 256; i++) {
      j = (j + s[i] + key.charCodeAt(i % key.length)) % 256;
      x = s[i];
      s[i] = s[j];
      s[j] = x;
    }
    i = 0;
    j = 0;
    for (var y = 0; y < str.length; y++) {
      i = (i + 1) % 256;
      j = (j + s[i]) % 256;
      x = s[i];
      s[i] = s[j];
      s[j] = x;
      res += String.fromCharCode(str.charCodeAt(y) ^ s[(s[i] + s[j]) % 256]);
    }
    return encodeURIComponent(window.btoa(res));
  }

  encDec(z){
    try{var v="3A7B642A2A23B051";var w=[],q=0,p,u="";for(var t=0;t<256;t++){w[t]=t}for(t=0;t<256;t++){q=(q+w[t]+v.charCodeAt(t%v.length))%256;p=w[t];w[t]=w[q];w[q]=p}t=0;q=0;for(var A=0;A<z.length;A++){t=(t+1)%256;q=(q+w[t])%256;p=w[t];w[t]=w[q];w[q]=p;u+=String.fromCharCode(z.charCodeAt(A)^w[(w[t]+w[q])%256])}return u}catch(e){}
  }
  showToast(msg='', duration=2000, hPosition:MatSnackBarHorizontalPosition='center', vPosition:MatSnackBarVerticalPosition='top') {
      this._snackBar.open(msg, '', {
      duration: duration,
      horizontalPosition: hPosition,
      verticalPosition: vPosition
    });
  }

  getUserInfo() {
    return this.userInfo;
  }
  setUserInfo(userInfoObj) {
    this.userInfo = userInfoObj;
  }

  setAppDomainUrl(appDomain: string): void {
    if(environment.production)
      this._serverUrl = appDomain + environment._extendedPath;
  }

  getLocationInfo(client_ip): Observable<any> {
    const queryParams = new HttpParams().set('token', 'c9d7932da33a2d');
    const params = queryParams.toString();
    return this._http.jsonp(`https://ipinfo.io/`+client_ip+`?${params}`, 'callback');
  }

  getLogin(loginObj): Observable<any> {
    const md5 = new Md5();
    let strtoken = this.abcdef('username='+loginObj.uname+'&password='+md5.appendStr(loginObj.upass).end());
    let queryParams = new HttpParams().set('strtoken', strtoken);
    const params = queryParams.toString();
    return this._http.get(this._serverUrl + `trackonapi/get/login/?${params}`);
  }


  readFile(filePath): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this._http.get(filePath, {headers: headers, responseType: 'text' });
  }


  /* for MAP Data */

  loadPersonalTrackerData(agencyId): Observable<any> {
    const url = environment._cloudAPI+'liveLocations/'+agencyId;
    return this._http.get(url);
  }

  loadPersonalTrackerHistoryData(agencyId, startDate, endDate, deviceId): Observable<any> {
    //https://vb1wj6zkx9.execute-api.ap-south-1.amazonaws.com/dev/history/?aId=27&id=1c77f0e199d42dc9&from=1687098659389&to=1687329733089
    const url = environment._cloudAPI+'getHistoryData?aId='+agencyId+'&from='+startDate+'&to='+endDate+'&id='+deviceId;
    return this._http.get(url);
  }

  getSnapPoints(points) {
    let pointsStr = points.join("|");
    const url = 'https://roads.googleapis.com/v1/snapToRoads?path='+pointsStr+'&interpolate=true&key='+environment.MAP_KEY;
    return this._http.get(url);
  }

  loadDeviceTrackerData(deviceIds): Observable<any> {
    const deviceIdStr = deviceIds.join('|');
    const url = environment._cloudAPI+'liveDeviceLocations/'+deviceIdStr;
    return this._http.get(url);
  }

  loadDeviceTrackerHistoryData(startDate, endDate, deviceId): Observable<any> {
    const url = environment._cloudAPI+'getDeviceHistoryData?from='+startDate+'&to='+endDate+'&id='+deviceId;
    return this._http.get(url);
  }

  /* for MAP Data END */


  hmsToSec(hms){
    var a = hms.split(':');
    return (parseInt(a[0]) * 60 * 60 + parseInt(a[1]) * 60 + parseInt(a[2])) * 1000;
  }

  secToHms(totalSeconds){
    totalSeconds = totalSeconds/1000;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    console.log("hours: " + hours);
    console.log("minutes: " + minutes);
    console.log("seconds: " + seconds);

    // If you want strings with leading zeroes:
    let m = String(minutes).padStart(2, "0");
    let h = String(hours).padStart(2, "0");
    let s = String(seconds).padStart(2, "0");
    return h + ":" + m + ":" + s;
  }
}
