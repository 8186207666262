import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService
  ) { 
  }

  ngOnInit(): void {
    this.appService.postLogout().subscribe(resp => {
      if(resp.success){
        
      }
    });

    let getRemember = null;
    if(localStorage.getItem('tkrm')){
      getRemember = localStorage.getItem('tkrm');
    }
    
    localStorage.clear();
    sessionStorage.clear();

    if(getRemember){
      localStorage.setItem('tkrm',getRemember);
    }

    this.router.navigate(['login']);
  }

}
