import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private _router: Router
  ) {}

  
  intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const twt = this.authService.getToken();
console.log(">>>",httpRequest.url);
    if(twt===null && httpRequest.url.indexOf('api/login') == -1 && httpRequest.url.indexOf('api/logout') == -1){
      this._router.navigate(['/logout']);
    }
    if(twt && httpRequest.url.indexOf('amazonaws.com') == -1) {
      return next.handle(httpRequest.clone({ setHeaders: { authorization: `Bearer ${twt}`  }}));
    }
    return next.handle(httpRequest);
  }
}
