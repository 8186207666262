import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import Swal from 'sweetalert2';
import { TkrServiceService } from '../services/tkr-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  year : Date = new Date();
  
  loading: boolean = false;
  rememberMe: boolean = false;
  constructor(
    private appService: AppService,
    private authService: AuthService, 
    private tkService: TkrServiceService,
    private router: Router
  ) { 

    if(localStorage.getItem('tkrm') != null){
      try {
        const rememberStr = JSON.parse(this.tkService.atob(localStorage.getItem('tkrm').replace(/\s/g,'')));
        this.userName = rememberStr.userName;
        this.userPass = rememberStr.userPass;
        this.rememberMe = true;
      }catch (e) {
        this.rememberMe = false;
      }
    }else
      this.rememberMe = false;

  }

  userName:string = '';
  userPass:string = '';
  loginError:string = '';

  ngOnInit(): void {
  }

  login() {

    /*if(this.userName==='admin' && this.userPass==='admin'){
      // perform login
      this.authService.login()

      this.router.navigate(['dashboard']);    
    }else{
      this.loginError = 'Invalid Username OR Password.'
    }*/
    
    this.loginError = '';
    if(this.userName!='' && this.userPass!=''){
      if(this.rememberMe){
        const strJSON = {"userName": this.userName, "userPass": this.userPass};
        localStorage.setItem('tkrm', this.tkService.btoa(JSON.stringify(strJSON)));
      }
      this.loading = true;
      let _self = this;
      this.appService.postLogin(this.userName, this.userPass).subscribe(resp => {
        this.loading = false;
        if(resp.success === false){
          this.loginError = resp.message;
        }else if(resp.success === true){
console.log("login DATA",resp.data);          
          localStorage.setItem('_lgData', _self.appService.btoa(JSON.stringify(resp.data)));
          sessionStorage.setItem('twt', 'twt!'+resp.token);
          if(this.rememberMe){
            localStorage.setItem('twt', 'twt!'+resp.token);
          }
          _self.authService.login()
          _self.router.navigate(['maps']);    
        }
      },
      err => {
        this.loginError = 'Invalid Username OR Password.';
        this.loading = false;
      });
    }else{
      this.loginError = 'Invalid Username OR Password.';
      this.loading = false;
    }
      
  }

  forgotPassword(){
    let _self = this;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        actions: 'justify-space-between',
        cancelButton: 'btn btn-danger',
        confirmButton: 'btn btn-success',
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      width: '400px',
      text: 'Enter registred email address',
      input: 'email',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#4caf50',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: (emailId) => {
        Swal.showLoading();
        return new Promise(function (resolve, reject) {
          let pSelf = this;
          _self.appService.resetPassword(emailId).subscribe((resp) => {
              if(resp.success === true){
                Swal.fire({
                  text: resp.data
                });
              }else{
                Swal.fire({
                  text: resp.message
                });
              }
              Swal.hideLoading();
            },
            error => {
              Swal.fire({
                text: 'Error resetting password, Try again later.'
              });
              Swal.hideLoading();
            });
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
       /*  Swal.fire({
          title: JSON.stringify(result.value)//`${result.value}`
        }) */
      }
    })
  }

  toggleRememberMe(): void {
    if(this.rememberMe === true){
      this.userName = '';
      this.userPass = '';
      //this.rememberMe = false;
      localStorage.removeItem('tkrm');
    }
  }

}
