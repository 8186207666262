<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute" data-color=purple>
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button mat-icon-button  (click)="sidebarMiniToggle()">
                    <i class="material-icons text_align-center visible-on-sidebar-regular">menu_open</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">menu</i>
                </button>
            </div>
            <!-- <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a> -->
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-start">
            <mat-select *ngIf="isSuperUser && showFilterAgencyList" class="filter-agency-select" [(ngModel)]="filterAgencyType" (selectionChange)="changeFilterAgencyType($event.value)" name="filterAgencyType">
                <mat-option value="0">All Agencies</mat-option>
                <mat-option *ngFor="let agencyType of agencyList" [value]="agencyType.id">{{agencyType.name}}</mat-option>
            </mat-select>
        </div>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <form class="navbar-form" style="display: none;">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form>
            <ul class="navbar-nav">
                <li class="nav-item">
                    
                </li>

                <li class="nav-item" style="display: none;">
                    <a class="nav-link" href="javascript:void(0)">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li>
                <li class="nav-item dropdown" style="display: none;">
                    <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0)">Mike John responded to your email</a>
                        <a class="dropdown-item" href="javascript:void(0)">You have 5 new tasks</a>
                        <a class="dropdown-item" href="javascript:void(0)">You're now friend with Andrew</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another Notification</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another One</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <label class="welcome-user-name">{{_userInfo.name}}</label>
                    <a class="nav-link pull-right" href="javascript:void(0)" id="userDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">person</i>
                        <b class="caret"></b>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdownMenuLink">
                        <a class="dropdown-item" (click)="showProfileInfo()"><i class="material-icons">account_circle</i>&nbsp;&nbsp;&nbsp;&nbsp;Profile</a>
                        <a class="dropdown-item" (click)="changeProfilePassword()"><i class="material-icons">key</i>&nbsp;&nbsp;&nbsp;&nbsp;Change Password</a>
                        <a class="dropdown-item" (click)="logoutMe()" routerLink="/logout" routerLinkActive="active"><i class="material-icons">power_settings_new</i>&nbsp;&nbsp;&nbsp;&nbsp;Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->