import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn = false; // initial login status
  loggedInData = null; // login data

  btoa(myObjStr){
    return btoa(unescape(encodeURIComponent(myObjStr)));
  }
  atob(myObjStr) {
    return decodeURIComponent(escape(atob(myObjStr)));
  }

  login() {
    // logic to perform login
    this.loggedIn = true;
    if(localStorage.getItem('_lgData')){
      this.loggedInData = JSON.parse(this.atob(localStorage.getItem('_lgData')));
    }
  }

  logout() {
    // logic to perform logout
    this.loggedIn = false;
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  isAuthenticated(): boolean {
    if(localStorage.getItem('_lgData') && (sessionStorage.getItem('twt') != null || localStorage.getItem('twt') != null)){
      this.login();
      return true;
    }
    return (this.loggedIn && this.loggedInData!=null);
  }

  getToken(): string {
    if(localStorage.getItem('tkrm') != null && localStorage.getItem('twt') != null){
      sessionStorage.setItem('twt', localStorage.getItem('twt'));
    }
    if(sessionStorage.getItem('twt') != null) {
      return sessionStorage.getItem('twt').replace('twt!','');
    }else{
      return null;
    }
  }
}
