// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  
  _serverDomain: location.protocol+'//localhost:4200/',
  _assetPath: location.protocol+'//localhost:4200/assets/',
  _extendedPath: '/',
  _filePath: 'https://test.com',
  _apiDomain: 'https://api.trackon.peerminds.in/api/', //'http://localhost:5000',
  _cloudAPI: 'https://oz4ve58dl8.execute-api.ap-south-1.amazonaws.com/', //AWS Cloud API Gateway Service
  fbNode: 'adm/Testing/',
  MAP_KEY:'AIzaSyB_9RorYTLbHdjaestIdtQeEjbwN8QGrEc',

  awsKey: "123=",
  awsSec: "123==",
  
  /*
  dbv:1,
  ytKey:'123',
  firebase: {
    apiKey: "123",
    authDomain: "123.firebaseapp.com",
    databaseURL: "https://123.firebaseio.com",
    projectId: "123",
    storageBucket: "123.appspot.com",
    messagingSenderId: "123",
    appId: "1:12:12:12"
  }*/
  
};
