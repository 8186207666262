<div class="card">
    <div class="card-header card-header-primary">
        Change Password
    </div>
    <div class="card-body">
        <div mat-dialog-content>
    
            <form [formGroup]="pwdFormValidations" >
                <mat-form-field >
                    <mat-label>Old Password</mat-label>
                    <input matInput required [(ngModel)]="data.oldPwd" formControlName="oldPwd">
                    <mat-error *ngIf="checkError('oldPwd','required')">Old password is required.</mat-error>
                    <mat-error *ngIf="checkError('oldPwd','pattern')">Old password is invalid.</mat-error>
                </mat-form-field>
    
                <mat-form-field >
                    <mat-label>New Password</mat-label>
                    <input matInput required [(ngModel)]="data.newPwd" formControlName="newPwd">
                    <mat-error *ngIf="checkError('newPwd','required')">New password is required.</mat-error>
                    <mat-error *ngIf="checkError('newPwd','pattern')">6 character length and Uppercase, Lowercase letters and ! . @ # $ % ^ & * are allowed.</mat-error>
                </mat-form-field>
    
                <mat-form-field >
                    <mat-label>Confirm Password</mat-label>
                    <input matInput required [(ngModel)]="data.confirmPwd" formControlName="confirmPwd">
                    <mat-error *ngIf="checkError('confirmPwd','required')">Confirm password is required.</mat-error>
                    <mat-error *ngIf="checkError('confirmPwd','confirmedValidator')">New Password and Confirm Password must be match.</mat-error>
                </mat-form-field>
            </form>
    
        </div>
        <div mat-dialog-actions style="justify-content: space-between;">
            <button mat-raised-button class="btn btn-danger" (click)="onCancelClick()">Cancel</button>
            <button mat-raised-button class="btn btn-success" [class.spinner]="loading" [disabled]="!pwdFormValidations.valid || loading" (click)="onSubmitClick()">Submit</button>
        </div>
    
    </div>
</div>



