import { Component, OnInit, ElementRef, Inject, Output, EventEmitter  } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

    private listTitles: any[];
    changePasswordDialogRef: MatDialogRef<ChangePasswordDialog>;
    editProfileDialogRef: MatDialogRef<EditProfileDialog>;
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    _userInfo = null;
    isSuperUser =false;
    agencyList = [];
    showFilterAgencyList = true;
    filterAgencyType;
    constructor(
        location: Location,  
        private element: ElementRef, 
        private router: Router,
        private authService: AuthService,
        private dialog: MatDialog,
        private appService: AppService
    ) {
        this.location = location;
        this.sidebarVisible = false;
        this._userInfo = this.authService.loggedInData;
        if(localStorage.getItem('_lgData')){
            this._userInfo = JSON.parse(this.authService.atob(localStorage.getItem('_lgData')));
        }

        this.filterAgencyType = this._userInfo.agency_id;
        if([1,2].indexOf(this._userInfo.user_type_id)!=-1) {
            this.isSuperUser = true;
            if(!this.agencyList.length){
                this.agencyList=[];
                this.appService.getAgencyList().subscribe((resp) => {
                  if(resp.data && resp.data.data && resp.data.data.length > 0){
                    resp.data.data.forEach((data) => {
                      if(data.is_active)
                        this.agencyList.push(data);
                    });
                    //this.agencyList = resp.data.data;
                  }
                });
            }
        }

    }

    ngOnInit(){
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event: any) => {
            if(this.router.url.indexOf('manage-agencies') !== -1) {
                this.showFilterAgencyList = false;
            }else{
                this.showFilterAgencyList = true;
            }
            this.sidebarClose();
            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });
    }

    changeFilterAgencyType(agencyId){
        console.log("SELECTED AGENCY TYPE>", agencyId);
        this.appService.setFilteredAgencyId(agencyId);
    }
 
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    sidebarMiniToggle(){
        document.body.classList.toggle('sidebar-mini');
    }

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].subRoute && this.listTitles[item].subRoute.length){
            for(var sItem = 0; sItem < this.listTitles[item].subRoute.length; sItem++){
                if(this.listTitles[item].subRoute[sItem].path === titlee){
                    return this.listTitles[item].subRoute[sItem].title;
                }
            }
        }
      }
      return 'Dashboard';
    }

    showProfileInfo(): void {
        if(this.changePasswordDialogRef)
            this.changePasswordDialogRef.close();

        this.editProfileDialogRef = this.dialog.open(EditProfileDialog, {
            hasBackdrop: true,
            width: '600px',
            height: 'auto',
            panelClass: 'trackon-dialog-card',
            data: { 
                    name: this._userInfo.name,
                    email: this._userInfo.email,
                    phone: this._userInfo.phone,
                    emp_id: this._userInfo.emp_id,
                    is_active: this._userInfo.is_active,
                    //group: new FormGroup({dob: new FormControl(this._userInfo.dob)})
                }
        });

        /*
        Swal.fire({
            title: '<u>User Profile</u>',
            html: //JSON.stringify(this._userInfo) + '<br>' +
                '<div class="profile-text-container">' +
                    '<b>User Name</b>: ' + this._userInfo.name + '<br>' +
                    '<b>Email</b>: ' + this._userInfo.email + '<br>' +
                    '<b>Phone</b>: ' + this._userInfo.phone + '<br>' +
                    '<b>DOB</b>: ' + this._userInfo.dob + '<br>' +
                    '<b>Employee ID</b>: ' + this._userInfo.emp_id + '<br>' +
                    '<b>Is Active</b>: ' + ((this._userInfo.is_active)?'YES':'NO') + '<br>' +
                '</div>',
            showCloseButton: true
        });
        */

    }

    changeProfilePassword(): void {
        this.changePasswordDialogRef = this.dialog.open(ChangePasswordDialog, {
            hasBackdrop: true,
            width: '350px',
            height: 'auto',
            panelClass: 'trackon-dialog-card'
        });
    }

    logoutMe(){
        if(this.changePasswordDialogRef)
            this.changePasswordDialogRef.close();
    }

}



@Component({
    selector: 'change-password-dialog',
    templateUrl: './change-password/change-password-dialog.html',
    styleUrls: ['./change-password/change-password-dialog.scss']
  })
  export class ChangePasswordDialog implements OnInit {
  
    data:any = { oldPwd:'', newPwd:'', confirmPwd:''};
    private _userInfo=null;
    pwdFormValidations: FormGroup;
    loading: boolean = false;

    constructor(
      public dialogRef: MatDialogRef<ChangePasswordDialog>,
      private appService: AppService,
      private formBuilder: FormBuilder,
      private authService: AuthService
    ){
        this._userInfo = this.authService.loggedInData;
    }
    ngOnInit(): void {
        this.pwdFormValidations = this.formBuilder.group({
            oldPwd: ['', [Validators.required]],
            newPwd: ['',[Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)]],
            confirmPwd: ['',[Validators.required]],
            },{
                validators: this.ConfirmedValidator('newPwd', 'confirmPwd')
            });
    }

    ConfirmedValidator(controlName: string, matchingControlName: string){
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    checkError(controlName: string, errorName: string) {
        return this.pwdFormValidations.controls[controlName].hasError(errorName);
    }
  
    onCancelClick(){
      this.dialogRef.close();
    }
  
    onSubmitClick(){
        if(this.data.newPwd===this.data.confirmPwd){
            this.loading = true;
            this.appService.changePassword(this._userInfo.id, this.data).subscribe((resp) => {
                this.loading = false;
                if(resp.success){
                    this.dialogRef.close();
                    Swal.fire({
                        icon: 'success',
                        title: 'Password updated successfully!',
                        showConfirmButton: false,
                        timer: 2000
                      });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to update password, Try Again.',
                        showConfirmButton: false,
                        timer: 2000
                      });
                }
            },
            (err) => {
                this.loading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to update password, Try Again.',
                    showConfirmButton: false,
                    timer: 2000
                  });
            });
        }else{

        }
    }

  }



  export interface ProfileDialogData {
    name: string;
    email: string;
    phone: string;
    emp_id: string;
    dob: string;
    address: string;
    state_id: number;
    city_id: number;
    is_active: boolean;
    group:FormGroup
  }

  @Component({
    selector: 'edit-profile-dialog',
    templateUrl: './edit-profile/edit-profile-dialog.html'
  })
  export class EditProfileDialog implements OnInit {
  
    _userInfo=null;
    profileFormValidations: FormGroup;
    loading: boolean = false;
    formDataLoading: boolean = false;
    stateList = [];
    cityList = [];

    constructor(
      public dialogRef: MatDialogRef<EditProfileDialog>,
      //@Inject(MAT_DIALOG_DATA) public data: ProfileDialogData,
      private appService: AppService,
      private formBuilder: FormBuilder,
      private authService: AuthService
    ){
        this._userInfo = this.authService.loggedInData;
        this.formDataLoading = true;
console.log("USER INFO>", this._userInfo)

        if(sessionStorage.getItem("states") == null){
            this.appService.getStateList().subscribe((resp) => {
                if(resp.data && resp.data.length > 0){
                    this.stateList = resp.data;
                    sessionStorage.setItem("states", JSON.stringify(this.stateList));
                }
            });
        }else{
            this.stateList = JSON.parse(sessionStorage.getItem("states"));
        }

        if(this._userInfo.state_id && this._userInfo.state_id!=0)
            this.getCityByState(this._userInfo.state_id);

    }
    ngOnInit(): void {
        this.profileFormValidations = this.formBuilder.group({
                name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9\\s]*$')]],
                email: ['', [Validators.required, Validators.minLength(5), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
                phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
                empId: ['',[Validators.pattern('^[a-zA-Z0-9]*$')]],
                dob: [''],
                address: [''],
                state_id: [''],
                city_id: [''],
            });
        this.profileFormValidations.disable();
    }

    checkError(controlName: string, errorName: string) {
        console.log(">>",errorName,this.profileFormValidations.controls[controlName].errors)
        return this.profileFormValidations.controls[controlName].hasError(errorName);
    }
  
    updateDateFormat(event: MatDatepickerInputEvent<Date>){
        //this.data.group.dob = moment(event.value).format('YYYY-MM-DD');
    }

    getCityByState(state_id): void {
        this.formDataLoading = true;
        this.cityList = [];
        this.appService.getCityList(state_id).subscribe((resp) => {
          if(resp.data && resp.data.length > 0){
            this.cityList = resp.data;
          }
          this.formDataLoading = false;
          this.profileFormValidations.enable();
        });
    }
    
    onCancelClick(){
      this.dialogRef.close();
    }
  
    onSubmitClick(){
        this.loading = true;
        this.appService.updateUserData(this._userInfo, this._userInfo.id).subscribe((resp) => {
            this.loading = false;
            if(resp.success){
                this.dialogRef.close();
                Swal.fire({
                    icon: 'success',
                    title: 'Profile updated successfully!',
                    showConfirmButton: false,
                    timer: 2000
                    });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to update profile, Try Again.',
                    showConfirmButton: false,
                    timer: 2000
                    });
            }
        },
        (err) => {
            this.loading = false;
            Swal.fire({
                icon: 'error',
                title: 'Unable to update profile, Try Again.',
                showConfirmButton: false,
                timer: 2000
                });
        });
    }

  }