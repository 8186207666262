import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

declare const $: any;
declare interface RouteInfo {
    path?: string;
    title: string;
    icon: string;
    class: string;
    isCollapsed?: boolean;
    subRoute?: RouteInfo[];
    roles?: number[];
    isValid?: boolean;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '', roles:[1,2,3,4,5]},
    { path: '/maps', title: 'Map',  icon: 'map', class: '', roles:[1,2,3,4,5]},
    { title: 'Administration',  icon:'admin_panel_settings', class: '', isCollapsed: false, roles:[1,2,3,4],
      subRoute: [
                  {path: '/manage-agencies', title: 'Agencies',  icon:'business', class: '', roles:[1,2]},
                  {path: '/manage-users', title: 'Users',  icon:'person', class: '', roles:[1,2,3]},
                  {path: '/manage-trackers', title: 'Personal Trackers',  icon:'install_mobile', class: '', roles:[1,2,3,4]},
                  {path: '/manage-devices', title: 'Devices',  icon:'devices', class: '', roles:[1,2,3,4]},
                  {path: '/manage-sims', title: 'Sim Cards',  icon:'sim_card', class: '', roles:[1,2,3,4]},
                  {path: '/manage-pois', title: 'POIs',  icon:'push_pin', class: '', roles:[1,2,3,4]},
                  {path: '/manage-device-types', title: 'Device Types',  icon:'developer_mode', class: '', roles:[1,2]},
                ]
    },
    /*{ path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },*/
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private authService: AuthService
  ) { 
  }

  ngOnInit() {
    let menuList = ROUTES.filter(menuItem => menuItem);
    menuList.forEach(menuItem => {
      if(menuItem.subRoute && menuItem.roles.length > 0 && menuItem.roles.indexOf(this.authService.loggedInData.user_type_id)!=-1) {
        menuItem.isValid = true;
        menuItem.subRoute.forEach(route => {
          if(route.roles.length > 0 && route.roles.indexOf(this.authService.loggedInData.user_type_id)!=-1) {
            route.isValid = true;
          }else{
            route.isValid = false;
          }
        });
      }else if(menuItem.roles.length > 0 && menuItem.roles.indexOf(this.authService.loggedInData.user_type_id)!=-1) {
        menuItem.isValid = true;
      }else{
        menuItem.isValid = false;
      }
    });

//console.log("menuItem>", menuList);    

    this.menuItems = menuList;
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  toggleSubMenu(menuObj:RouteInfo){
    console.log("MENU SELECTED:", menuObj)
    menuObj.isCollapsed = !menuObj.isCollapsed;
  }
}
