<div class="logo">
    <a href="https://www.trackon.com" class="simple-text">
        <div class="logo-img">
            <div class="logo-icon"></div>
        </div>
        <div class="logo-text">Track<b>On</b></div>
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form" style="display: none;">
            <span class="bmd-form-group">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </span>
        </form>
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item" style="display: none">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">dashboard</i>
                    <p>
                        <span class="d-lg-none d-md-block">Stats</span>
                    </p>
                </a>
            </li>
            <li class="nav-item dropdown" style="display: none">
                <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                        <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Mike John responded to your email</a>
                    <a class="dropdown-item" href="#">You have 5 new tasks</a>
                    <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                    <a class="dropdown-item" href="#">Another Notification</a>
                    <a class="dropdown-item" href="#">Another One</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Account</span>
                    </p>
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav">
        <ng-container *ngFor="let menuItem of menuItems">
    <li routerLinkActive="active" *ngIf="menuItem.isValid" class="{{menuItem.class}} nav-item">
                <a class="nav-link" *ngIf="!menuItem.subRoute" [routerLink]="[menuItem.path]">
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}}</p>
                </a>
                <a class="nav-link" *ngIf="menuItem.subRoute && menuItem.isValid"  data-toggle="collapse" [aria-expanded]="menuItem.isCollapsed" (click)="menuItem.isCollapsed=!menuItem.isCollapsed">
                    <i class="material-icons">{{menuItem.icon}}</i>
                    <p>{{menuItem.title}}
                        <b [class]="{'caret':menuItem.isCollapsed, 'caret-rw':!menuItem.isCollapsed}"></b>
                    </p>
                </a>
                <div *ngIf="menuItem.subRoute && menuItem.isValid" [class]="{'collapse':menuItem.isCollapsed, 'collapsed':!menuItem.isCollapsed}">
                    <ul class="nav">
                        <ng-container *ngFor="let subMenuItem of menuItem.subRoute">
                            <li routerLinkActive="active" *ngIf="subMenuItem.isValid" class="{{subMenuItem.class}} nav-item">
                                <a class="nav-link" [routerLink]="[subMenuItem.path]">
                                    <i class="material-icons">{{subMenuItem.icon}}</i>
                                    <p>{{subMenuItem.title}}</p>
                                </a>
                            </li>                  
                        </ng-container>
                    </ul>
                </div>

            </li>
        </ng-container>
        
    </ul>
</div>