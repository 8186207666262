<!-- <div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                &nbsp;
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header card-header-purple">
                        <h4 class="card-title">Login</h4>
                        < !-- <p class="card-category">Complete your profile</p> -- >
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row">
                                
                                <div class="col-md-3">
                                    <mat-form-field class="example-full-width">
                                      <input matInput placeholder="Username">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                      <input matInput placeholder="Password">
                                    </mat-form-field>
                                </div>
  
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <a href="javascript:void(0)" class="text-primary">Forgot Password</a>
                                </div>
                            </div>
                            <button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Profile</button>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                &nbsp;
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
    <form novalidate="" method="" action="" class="form ng-untouched ng-pristine ng-valid">
        <div class="card card-login card-hidde1n">
            <div class="card-header card-header-purple text-center">
                <h4 class="card-title">Log in</h4>
            </div>
            <div class="card-body">
                
                <span class="bmd-form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="material-icons">face</i>
                            </span>
                        </div>
                        <mat-form-field class="width-80">
                            <input matInput placeholder="Username">
                        </mat-form-field>
                    </div>
                </span>
                <span class="bmd-form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="material-icons">lock_outline</i>
                            </span>
                        </div>
                        <mat-form-field class="width-80">
                            <input matInput placeholder="Password">
                        </mat-form-field>
                    </div>
                </span>
            </div>
            <div class="card-footer justify-content-center"><a href="#pablo" class="btn btn-rose btn-link btn-lg">Lets
                    Go</a></div>
        </div>
    </form>
</div> -->

<div class="wrapper wrapper-full-page">
    <div filter-color="black" class="page-header login-page header-filter"
        style="background-image: url('./assets/img/login.jpg'); background-size: cover; background-position: top center;height: 100%;">
        <!-- <nav class="navbar navbar-expand-lg navbar-transparent">
            <div class="container-fluid">
                <div class="navbar-wrapper">
                    <span class="logo">
                        <a href="https://www.trackon.com" class="simple-text">
                            <div class="logo-img">
                                <img src="/assets/img/trackon-logo.png" />
                            </div>
                        </a>
                    </span>
                </div>
            </div>
        </nav> -->
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
                    <div class="login-logo-wrapper">
                        <div class="logo">
                            <a href="https://www.trackon.com">
                                <div class="logo-img pull-left">
                                    <img src="/assets/img/trackon-logo.png" />
                                </div>
                            </a>
                        </div>
                        <div class="home-title pull-right">
                            Track<b>On</b>
                        </div>
                    </div>
                    <form novalidate="" method="" action="" class="form ng-untouched ng-pristine ng-valid">
                        <div class="card card-login" style="margin-top: 0;">
                            <!-- <div class="card-header card-header-rose text-center">
                                <h4 class="card-title">Log in</h4>
                            </div> -->
                            <div class="card-body">
                                <h4 class="card-title text-center">
                                    <span class="text-danger" *ngIf="loginError!=''">{{loginError}}</span>
                                </h4>
                                <span class="bmd-form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="material-icons">person_outline</i>
                                            </span>
                                        </div>
                                        <mat-form-field class="width-75">
                                            <input [(ngModel)]="userName" name="userName" matInput placeholder="Username">
                                        </mat-form-field>
                                    </div>
                                </span>
                                <span class="bmd-form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="material-icons">lock_outline</i>
                                            </span>
                                        </div>
                                        <mat-form-field class="width-75">
                                            <input [(ngModel)]="userPass" matInput name="userPass" placeholder="Password" type="password">
                                        </mat-form-field>
                                    </div>
                                </span>
                                <span class="bmd-form-group" style="padding-left: 20px;">
                                    <mat-checkbox color="primary" class="remember-me" style="margin-top: 5px;" name="rememberMe1" [(ngModel)]="rememberMe" (click)="toggleRememberMe()" >Remember me</mat-checkbox>
                                </span>
                                <span class="text-right" style="float: right;margin: 10px 20px 0;">
                                    <a (click)="forgotPassword()" class="text-primary">Forgot Password?</a>
                                </span>
                            </div>

                            
                            <div class="card-footer justify-content-center">
                                <button mat-raised-button type="submit" [class.spinner]="loading" [disabled]="loading" (click)="login()" href="#" class="btn btn-blue">Log in</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>        
<footer class="footer">
    <div class="container">
        <nav class="pull-left">
            <ul>
                <!-- <li><a href="https://peerminds.in"> About Us </a></li> -->
                <!-- <li><a href="https://www.trackon.com/license"> Licenses </a></li> -->
            </ul>
        </nav>
        <div class="copyright pull-right">Copyright © {{year | date: 'yyyy'}} | A product by <a href="https://www.trackon.com" target="_blank">Peerminds Solutions</a></div>
    </div>
</footer>
