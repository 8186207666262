import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private SERVER_URL = environment._apiDomain;
  private _filteredAgencyId = new BehaviorSubject('');
  getFilteredAgencyId = this._filteredAgencyId.asObservable();


  DEVICE_STATUS_CODES = {
    OFFLINE:  { code:'OFFLINE', label:'OFFLINE',  title:'Device is Offline',  color: 'darkgrey' },
    LIVE:     { code:'LIVE',    label:'LIVE',     title:'Device is Live',     color: 'limegreen' },
    IDLE:     { code:'IDLE',    label:'IDLE',     title:'Device is Idle',     color: 'orange' },
    STOPPED:  { code:'STOPPED', label:'STOPPED',  title:'Device is Stopped',  color: 'red' }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _http: HttpClient
  ) { }

  btoa(myObjStr){
    return btoa(unescape(encodeURIComponent(myObjStr)));
  }
  atob(myObjStr) {
    return decodeURIComponent(escape(atob(myObjStr)));
  }

  showToast(msg='', duration=2000, hPosition:MatSnackBarHorizontalPosition='center', vPosition:MatSnackBarVerticalPosition='top') {
      this._snackBar.open(msg, '', {
      duration: duration,
      panelClass: ['center-snackbar'],
      horizontalPosition: hPosition,
      verticalPosition: vPosition
    });
  }

  setFilteredAgencyId(agencyId): void {
    this._filteredAgencyId.next(agencyId);
  }

  /* Common API START */
    postLogin(username, password): Observable<any> {
      const params = {email: username, password: password}
      return this._http.post(this.SERVER_URL + 'login', params);
    }

    postLogout(): Observable<any> {
      const params = {}
      return this._http.post(this.SERVER_URL + 'logout', params);
    }

    resetPassword(emailId): Observable<any> {
      const params = {email:emailId};
      return this._http.post(this.SERVER_URL + 'forgotpassword', params);
    }

    changePassword(userId, pwdData): Observable<any> {
      const params = {userId:userId, oldPassword:pwdData.oldPwd, newPassword:pwdData.newPwd, confirmPassword:pwdData.confirmPwd};
      return this._http.post(this.SERVER_URL + 'changepassword', params);
    }

    getAgencyList(): Observable<any> {
      const href = this.SERVER_URL+'agencylist';
      const requestUrl = `${href}`;
      const params = {
        "search": '',
        "sortBy": "id", 
        "orderBy": "asc", 
        "pageSize": 200,
        "pageNo": 1
      }
      return this._http.post(requestUrl, params);    
    }

    getStateList(): Observable<any> {
      const href = this.SERVER_URL+'statelist';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {});    
    }

    getCityList(stateId): Observable<any> {
      const href = this.SERVER_URL+'citylist';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {stateId: stateId});    
    }

    getUserTypes(): Observable<any> {
      const href = this.SERVER_URL+'usertypelist';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {});    
    }

    lessThanHourAgo = (date, noOfHours=1) => {
      //const HOUR = 1000 * 60 * 60;
      //const anHourAgo = Date.now() - (HOUR * noOfHours);
  
      return moment(date).isAfter(moment().subtract(noOfHours, 'hours'));
    }

  // Common API END

  /* Agency API */
    getAgencies(sort: string, order: string, page: number, pageSize: number, filterValue: string): Observable<any> {
      const params = {
        "search": filterValue,
        "sortBy": sort || "id", 
        "orderBy": order || "desc", 
        "pageSize": pageSize,
        "pageNo": (page + 1)
      }
      return this._http.post(this.SERVER_URL + 'agencylist', params);
    }

    deleteAgency(agencyId:number) {
      const href = this.SERVER_URL+'deleteagency';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {agencyId: agencyId});        
    }

    toggleAgencyActiveStatus(agencyId:number, isActive: boolean) {
      const href = this.SERVER_URL+'changeagencystatus';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {agencyId: agencyId, status: !isActive});        
    }

    updateAgencyData(row, userId): Observable<any> {
      const userData:any = {
        "name": row.name,
        "phone": row.phone,
        "email": row.email,
        "address": row.address,
        "appVersion": row.app_version,
        "logo": row.logo || '',
        "logoSmall": row.logo_small || '',
        "mapKey": row.map_key,
        "baseLocation": row.base_location,
        //"cityId": row.city_id,
        //"stateId": row.state_id,
        "isActive": row.is_active
      }

      let href = this.SERVER_URL+'addagency';
      if(row.id && row.id !== 0){
        href = this.SERVER_URL+'editagency';
        userData.agencyId = row.id;
        userData.updatedBy = userId;
      }else{
        userData.createdBy = userId;
        userData.updatedBy = userId;
      }
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, userData);        
    }
  /* Agency API END */

  /* Users API */
    getUsers(userId: number, agencyId:number, sort: string, order: string, page: number, pageSize: number, filterValue: string): Observable<any> {
      const params = {
        "userType": userId, 
        "agencyId": agencyId, 
        "search": filterValue,
        "sortBy": sort || "id", 
        "orderBy": order || "desc", 
        "pageSize": pageSize,
        "pageNo": (page + 1)
      }
      return this._http.post(this.SERVER_URL + 'userlist', params);
    }

    deleteUser(userId:number) {
      const href = this.SERVER_URL+'deleteuser';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {userId: userId});        
    }

    toggleUserActiveStatus(userId:number, isActive: boolean) {
      const href = this.SERVER_URL+'changeuserstatus';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {userId: userId, status: !isActive});        
    }

    updateUserData(user, userId): Observable<any> {
      const userData:any = {
        "name": user.name,
        "email": user.email,
        "phone": user.phone,
        "address": user.address,
        "userTypeId": user.user_type_id,
        "empId": user.emp_id,
        "dob": user.dob,
        "cityId": user.city_id,
        "stateId": user.state_id,
        "isActive": user.is_active
      }

      let href = this.SERVER_URL+'adduser';
      if(user.id && user.id !== 0){
        href = this.SERVER_URL+'edituser';
        userData.agencyId = user.agency_id;
        userData.userId = user.id;
        userData.updatedBy = userId;
      }else{
        userData.email = user.email;
        userData.agencyId = user.agency_id;
        userData.createdBy = userId;
        userData.updatedBy = userId;
      }

      const requestUrl = `${href}`;
      return this._http.post(requestUrl, userData);        
    }
  /* Users API END */


  /* Devices API */
    getDevices(agencyId:number, sort: string, order: string, page: number, pageSize: number, filterValue: string): Observable<any> {
      const params = {
        "agencyId": agencyId,
        "search": filterValue,
        "sortBy": sort || "id", 
        "orderBy": order || "desc", 
        "pageSize": pageSize,
        "pageNo": (page + 1)
      }
      return this._http.post(this.SERVER_URL + 'devicelist', params);
    }

    deleteDevice(deviceId:number) {
      const href = this.SERVER_URL+'deletedevice';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {deviceId: deviceId});        
    }

    toggleDeviceActiveStatus(deviceId:number, isActive: boolean) {
      const href = this.SERVER_URL+'changedevicestatus';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {deviceId: deviceId, status: !isActive});        
    }

    updateDeviceData(row, userId): Observable<any> {
      const userData:any = {
        "name": row.name,
        "device_no": row.device_no,
        "deviceTypeId": row.device_type_id,
        "deviceUserTypeId": row.device_user_type_id,
        "description": row.description,
        "userId": row.user_id,
        "simId": row.sim_id,
        "sosNo1": parseInt(row.sos_no1),
        "agencyId": row.agency_id,
        "isActive": row.is_active
      }

      if(row.sos_no2 && row.sos_no2 != ""){
        userData.sosNo2 = parseInt(row.sos_no2)
      }

      let href = this.SERVER_URL+'adddevice';
      if(row.id && row.id !== 0){
        href = this.SERVER_URL+'editdevice';
        userData.deviceId = row.id;
        userData.updatedBy = userId;
      }else{
        userData.createdBy = userId;
        userData.updatedBy = userId;
      }
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, userData);        
    }
  /* Devices API END */

  /* Personal Trackers API */

  getPersonalUsers(agencyId:number, sort: string, order: string, page: number, pageSize: number, filterValue: string, isActive:boolean = false): Observable<any> {
    const params = {
      "agencyId": agencyId, 
      "search": filterValue,
      "sortBy": sort || "id", 
      "orderBy": order || "desc", 
      "pageSize": pageSize,
      "pageNo": (page + 1),
      "isActive": (isActive)?1:0
    }
    return this._http.post(this.SERVER_URL + 'personaluserlist', params);
  }

  deletePersonalUser(personalUserId:number) {
    const href = this.SERVER_URL+'deletepersonaluser';
    const requestUrl = `${href}`;
    return this._http.post(requestUrl, {personalUserId: personalUserId});        
  }

  resetPersonalUser(personalUserId:number) {
    const href = this.SERVER_URL+'personalresetdeviceid';
    const requestUrl = `${href}`;
    return this._http.post(requestUrl, {personalUserId: personalUserId});        
  }

  togglePersonalUserActiveStatus(personalUserId:number, isActive: boolean) {
    const href = this.SERVER_URL+'changepersonaluserstatus';
    const requestUrl = `${href}`;
    return this._http.post(requestUrl, {personalUserId: personalUserId, status: !isActive});        
  }

  updatePersonalUserData(user, userId): Observable<any> {
    const userData:any = {
      "name": user.name,
      "phone": user.phone,
      "email": user.email,
      "address": user.address,
      "cityId": user.city_id,
      "stateId": user.state_id,
      "deviceId": user.device_id,
      "agencyId": user.agency_id,
      "photo": user.photo,
      "sos_no1": user.sos_no1,
      "sos_no2": user.sos_no2,
      "isActive": user.is_active
    }

    let href = this.SERVER_URL+'addpersonaluser';
    if(user.id && user.id !== 0){
      href = this.SERVER_URL+'editpersonaluser';
      userData.personalUserId = user.id;
      userData.updatedBy = userId;
    }else{
      userData.email = user.email;
      userData.agencyId = user.agency_id;
      userData.createdBy = userId;
      userData.updatedBy = userId;
    }

    const requestUrl = `${href}`;
    return this._http.post(requestUrl, userData);        
  }

  /* Personal Trackers API END */

  /* SIM API */

    getSimList(agencyId: number, sort: string, order: string, page: number, pageSize: number, filterValue: string, isActive='', isAvailable=''): Observable<any> {
      const params = {
        "agencyId": agencyId,
        "search": filterValue,
        "sortBy": sort || "id", 
        "orderBy": order || "desc", 
        "pageSize": pageSize,
        "pageNo": (page + 1),
        "isActive": isActive,
        "isAvailable": isAvailable
      }
      return this._http.post(this.SERVER_URL + 'simlist', params);
    }

    deleteSim(simId:number) {
      const href = this.SERVER_URL+'deletesim';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {simId: simId});        
    }
  
    toggleSimActiveStatus(simId:number, isActive: boolean) {
      const href = this.SERVER_URL+'changesimstatus';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {simId: simId, status: !isActive});        
    }
  
    updateSimData(simObj, userId): Observable<any> {
      const userData:any = {
        "sim_no": simObj.sim_no,
        "serial_no": simObj.serial_no,
        "operator": simObj.operator,
        "validity": simObj.validity,
        "agencyId": simObj.agency_id,
        "isActive": simObj.is_active
      }
  
      let href = this.SERVER_URL+'addsim';
      if(simObj.id && simObj.id !== 0){
        href = this.SERVER_URL+'editsim';
        userData.simId = simObj.id;
        userData.updatedBy = userId;
      }else{
        userData.createdBy = userId;
        userData.updatedBy = userId;
      }
  
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, userData);        
    }

  /* SIM API END */

  /* Device Type List */

    getDeviceTypeList(): Observable<any> {
      const href = this.SERVER_URL+'devicetypelist';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {});    
    }
    
  /* Device Type END */

  /* Device User Type List */

    getDeviceUserTypeList(deviceType): Observable<any> {
      const href = this.SERVER_URL+'deviceusertypelist';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {deviceTypeId:deviceType});    
    }
    
  /* Device Type END */

  /* POI API */

    getPoiList(agencyId: number, sort: string, order: string, page: number, pageSize: number, filterValue: string, isActive: boolean = false): Observable<any> {
      const params = {
        "agencyId": agencyId,
        "search": filterValue,
        "sortBy": sort || "id", 
        "orderBy": order || "desc", 
        "pageSize": pageSize,
        "pageNo": (page + 1),
        "isActive": (isActive)?1:0
      }
      return this._http.post(this.SERVER_URL + 'poilist', params);
    }

    deletePoi(poiId:number) {
      const href = this.SERVER_URL+'deletepoi';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {poiId: poiId});        
    }
  
    togglePoiActiveStatus(poiId:number, isActive: boolean) {
      const href = this.SERVER_URL+'changepoistatus';
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, {poiId: poiId, isActive: !isActive});        
    }
  
    updatePoiData(poiObj, userId): Observable<any> {
      const userData:any = {
        "name": poiObj.name,
        "type": poiObj.type,
        "agencyId": poiObj.agency_id,
        "lat": poiObj.lat,
        "lon": poiObj.lon,
        "isActive": poiObj.is_active
      }
  
      let href = this.SERVER_URL+'addpoi';
      if(poiObj.id && poiObj.id !== 0){
        href = this.SERVER_URL+'editpoi';
        userData.poiId = poiObj.id;
        userData.updatedBy = userId;
      }else{
        userData.createdBy = userId;
        userData.updatedBy = userId;
      }
  
      const requestUrl = `${href}`;
      return this._http.post(requestUrl, userData);        
    }

  /* POI API END */

}
